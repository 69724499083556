<template>
  <div v-if="this.$ekmtcCommon.isNotEmpty(detailList)" class="location_detail_box">
    <div class="location_detail_header">
      <div class="">
        <div class="ship_num">{{ detailParams.cntrNo }}</div>
        <!-- 메시지 나오는 부분 -->
        <div v-if="messageRk == 1" v-html="this.getMessage(detailList[0])" class="ship_txt"></div>
        <div v-else-if="messageRk == 5" v-html="this.getMessage(detailList[0])" class="ship_txt"></div>
        <div v-else-if="messageRk >= 7 && messageRk <= 9" v-html="this.getMessage(detailList[0])" class="ship_txt"></div>
        <div v-else-if="messageRk >= 10 && messageRk <= 11">
          <div v-if="detailList[0].ctrCd === 'IN' && detailList[0].plcCd == detailList[0].podPortCd && detailList[0].podPortCd != detailList[0].dlyPlcCd && messageRk == 10" class="ship_txt">
            {{ $t('msg.CART010G010.058') }}<!-- FULL CNTR 터미널에서 반출 -->
          </div>
          <div v-else-if="detailList[0].plcCd == detailList[0].podPortCd && detailList[0].podPortCd != detailList[0].dlyPlcCd && messageRk == 10" v-html="this.getMessage(detailList[0])" class="ship_txt"></div>
          <!-- 에서 Outside depot로 이송되었습니다. -->
          <div v-else v-html="this.getMessage(detailList[0])"></div>
        </div>
        <div v-else>
          <div v-if="detailList[0].ctrCd === 'IN' && detailList[0].plcCd == detailList[0].podPortCd && detailList[0].podPortCd != detailList[0].dlyPlcCd && messageRk == 3">
            {{ $t('msg.CART010G010.059') }}<!-- FULL CNTR 터미널에서 반입 -->
          </div>
          <div v-else v-html="this.getMessage(detailList[0])"></div>
        </div>
      </div>
      <!-- 내륙 시간 정보 -->
      <div>
        <div v-if="this.$ekmtcCommon.isNotEmpty(averageParams) && this.$ekmtcCommon.isNotEmpty(detailInlandList)" class="ship_time">
          <strong>{{ averageParams.podPortNm }}({{ averageParams.podPortCd }}) - {{ averageParams.dlyPlcNm }}</strong>
          <p>{{ $t('msg.CART010G010.057') }} <span>{{ averageParams.avgTnsDrtm }}</span></p>
        </div>
      </div>
    </div>

    <!-- 화물추적 현황 -->
    <ul class="location_detail">
      <!-- Empty Container Pick Up -->
      <li
        :style="detailParams.cntrTypCd !== 'TK' ? 'display: block;' : 'display: none;'"
        :class="[(rank < 3 ? 'active' : '')]"
      >
        <div class="ico ico_pickup">pickup</div>
        <div class="txt">
          Empty <br> Container<br> Pick up
        </div>
        <div class="ts_scroll">
          <div>
            <p>Empty<br> Gate out</p>
            <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step01.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step01.mvntTm, ':') }}</span></p>
          </div>
        </div>
      </li>

      <!-- Port Of Loading -->
      <li :class="[rank >= 3 ? '' : (step02 !== undefined && Object.keys(step02).length > 0 ? 'active' : 'inactive')]">
        <div class="ico ico_loading">loading</div>
        <div class="txt">
          Port of <br>Loading
        </div>
        <div class="ts_scroll">
          <div>
            <p>Gate In </p>
            <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step02.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step02.mvntTm, ':') }}</span></p>
          </div>
        </div>
      </li>

      <!-- On Board -->
      <li :class="[rank > 4 ? '' : (step03 !== undefined && Object.keys(step03).length > 0 ? 'active' : 'inactive')]">
        <div class="ico ico_board">board</div>
        <div class="txt">
          On board
        </div>
        <div class="ts_scroll">
          <div>
            <p>Loading</p>
            <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step03.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step03.mvntTm, ':') }}</span></p>
          </div>
        </div>
      </li>

      <!-- T/S -->
      <!-- <li :style="tsList.length > 0 && detailParams.cntrTypCd !== 'TK' ? 'display: block;' : 'display: none;'" :class="[tsList.length > 0 ? '' : (step03 !== undefined && Object.keys(step03) > 0 ? 'active' : 'inactive')]"> -->
      <li :style="tsList.length > 0 && detailParams.cntrTypCd !== 'TK' ? 'display: block;' : 'display: none;'" :class="[tsList.length > 0 ? (rank === 6 ? 'active' : '') : 'inactive']">
        <div class="ico ico_transhipped">transhipped</div>
        <div class="txt">
          (Transhipped)
        </div>
        <div class="ts_scroll">
          <div :v-if="tsList.length > 0 && tsInfo.cntrMvntCd === 'TS'" :key="tsInfo.rkCode">
            <p> T/S {{ tsInfo.tsIdx + 1 }}  <br /> {{ cntrStsNm }} </p>
            <p class="date">{{ this.$ekmtcCommon.changeDatePattern(tsInfo.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(tsInfo.mvntTm, ':') }}</span></p>
          </div>
          <button :class="[tsInfo.tsIdx === 0 ? 'btns prev disabled' : 'btns prev']" @click.prevent="preInfo(tsInfo.tsIdx, 'TS')">이전</button>
          <button :class="[tsInfo.tsIdx === tsInfo.tsCnt - 1 ? 'btns next disabled' : 'btns next']" @click.prevent="nextInfo(tsInfo.tsIdx, 'TS')">다음</button>
        </div>
      </li>

      <!-- Port Of Discharging -->
      <!-- <li :class="[rank > 7 && detailParams.cntrTypCd === 'TK' ? 'active' : rank > 7 || detailInlandList.length > 0 ? '' : tsList.length > 0 ? 'ts_scroll active' : 'inactive']"> -->
      <li :class="[rank > 7 && detailParams.cntrTypCd === 'TK' ? 'active' : rank > 7 || (detailInlandList.length > 0 && (dlyCtrCd === 'IN' || dlyCtrCd === 'ID')) ? '' : 'inactive']">
        <div class="ico ico_discharging">discharging</div>
        <div class="txt">
          Port of<br> Discharging
        </div>
        <div class="ts_scroll">
          <div>
            <p>Discharging </p>
            <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step04.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step04.mvntTm, ':') }}</span></p>
          </div>
        </div>
      </li>

      <!-- 인도 내륙 건일때 (ICD, CFS 이동)-->
      <template v-if="dlyCtrCd === 'IN' && (inland.length > 0 || inlandListIn.length > 0)">
        <!-- train -->
        <li :style="detailParams.cntrTypCd !== 'TK' && cyCfsYn === 'N'? 'display: block;' : 'display: none;'" :class="[rank > 9 ? '' : step05 !== undefined && Object.keys(step05).length > 0 ? 'ts_scroll active' : 'inactive']">
          <div class="ico ico_train">train_off</div>
          <div class="txt">
            Train
          </div>
          <div class="ts_scroll">
            <div>
              <p v-if="detailParams.icdTruckage === 'Y'">Truckage & ON RAIL</p>
              <p v-else>IN TRANSIT ON RAIL</p>
              <!-- <p class="date">{{ inlandEtdDay }} <br> {{ inlandEtdTime }}</p> -->
              <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step05.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step05.mvntTm, ':') }}</span></p>
            </div>
          </div>
        </li>

        <!-- ICD -->
        <li :style="detailParams.cntrTypCd !== 'TK' && cyCfsYn === 'N' ? 'display: block;' : 'display: none;'" :class="[rank > 9 ? '' : step05_1 !== undefined && Object.keys(step05_1).length > 0 ? 'ts_scroll active' : 'inactive']">
          <div class="ico ico_icd">ICD</div>
          <div class="txt">
            Inland Contatiner Depot
          </div>
          <div class="ts_scroll">
            <div>
              <p>Arrival</p>
              <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step05_1.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step05_1.mvntTm, ':') }}</span></p>
            </div>
          </div>
        </li>

        <!-- Gate Out -->
        <li :style="detailParams.cntrTypCd === 'TK' ? 'display: none;' : 'display: block;'" :class="[rank > 11 ? '' : step07 !== undefined && Object.keys(step07).length > 0 ? 'ts_scroll active' : 'inactive']">
          <div class="ico ico_pickup">Gate Out</div>
          <div class="txt">
            Gate<br> Out
          </div>
          <div class="ts_scroll">
            <div>
              <p>Full <br>Gate Out </p>
              <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step07.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step07.mvntTm, ':') }}</span></p>
            </div>
          </div>
        </li>

        <!-- Return -->
        <li :style="detailParams.cntrTypCd !== 'TK' ? 'display: block;' : 'display: none;'" :class="[rank > 12 ? 'active' : 'inactive']">
          <div class="ico ico_return">Return</div>
          <div class="txt">
            <div>Return</div>
          </div>
          <div class="ts_scroll">
            <div>
              <p>Empty <br>Gate In</p>
              <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step08.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step08.mvntTm, ':') }}</span></p>
            </div>
          </div>
        </li>
      </template>

      <template v-else>
        <!-- Gate Out -->
        <li :style="(detailInlandList.length > 0 && dlyCtrCd === 'ID')|| detailParams.cntrTypCd === 'TK' ? 'display: none;' : 'display: block;'" :class="[rank > 9 ? '' : step05 !== undefined && Object.keys(step05).length > 0 ? 'ts_scroll active' : 'inactive']">
          <div class="ico ico_pickup">Gate Out</div>
          <div class="txt">
            Gate<br> Out
          </div>
          <div class="ts_scroll">
            <div>
              <p>Full <br>Gate Out </p>
              <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step05.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step05.mvntTm, ':') }}</span></p>
            </div>
          </div>
        </li>

        <!-- InLand -->
        <li :style="(detailInlandList.length > 0 && dlyCtrCd === 'ID')&& detailParams.cntrTypCd !== 'TK' ? 'display: block;' : 'display: none;'" :class="rank > 10 ? '' : [inland.length > 0 ? 'ts_scroll active' : 'inactive']">
          <div class="ico ico_train">train_off</div>
          <div class="txt">
            Train
          </div>
          <div class="ts_scroll">
            <div v-if="inlandFlag === 'D'">
              <p>Inland ETD</p>
              <p class="date">{{ inlandEtdDay }} <br> {{ inlandEtdTime }}</p>
            </div>
            <div v-if="inlandFlag === 'A'">
              <p>Inland ETA</p>
              <p class="date">{{ inlandEtaDay }} <br> {{ inlandEtaTime }}</p>
            </div>
            <button :class="[inlandFlag === 'D' ? 'btns prev disabled' : 'btns prev']" @click.prevent="chgInlandFlag('D')">이전</button>
            <button :class="[inlandFlag === 'A' ? 'btns next disabled' : 'btns next']" @click.prevent="chgInlandFlag('A')">다음</button>
          </div>
        </li>

        <!-- Return -->
        <li :style="detailParams.cntrTypCd !== 'TK' ? 'display: block;' : 'display: none;'" :class="[rank > 10 ? 'active' : 'inactive']">
          <div class="ico ico_return">Return</div>
          <div class="txt">
            <div>Return</div>
          </div>
          <div class="ts_scroll">
            <div>
              <p>Empty <br>Gate In</p>
              <p class="date">{{ this.$ekmtcCommon.changeDatePattern(step06.mvntDt, '-') }} <span>{{ this.$ekmtcCommon.changeTimePattern(step06.mvntTm, ':') }}</span></p>
            </div>
          </div>
        </li>
      </template>
    </ul>
  </div><!-- location_detail_box // -->
</template>

<script>
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'CargoLocationDetail',
  components: {
    EBreadcrumbs,
    EDateRangePicker: () => import('@/components/common/EDateRangePickerForOog'),
    EDatePicker: () => import('@/components/common/EDatePicker'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
  },
  props: {
    detailDateList: {
      type: Array,
      default: null
    },
    detailList: {
      type: Array,
      default: null
    },
    detailInlandList: {
      type: Array,
      default: null
    },
    inlandListIn: {
      type: Array,
      default: null
    },
    detailParams: {
      type: Object,
      default: null
    },
    averageParams: {
      type: Object,
      default: null
    },
    rank: {
      type: Number,
      default: null
    },
    dateFlag: {
      type: String,
      default: ''
    },
    lang: {
      type: String,
      default: ''
    },
    dlyCtrCd: {
      type: String,
      default: ''
    },
    cyCfsYn: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      customComponent: null,
      cntrArr: {},
      cntrCnt: 0,
      messageRk: 0,
      //컨테이너 detail 관련 정보
      trackingDateList: [],
      trackingList: [],
      //로그인 없이 비엘 임시로 조회 시 imsi 제한 BL체크
      imsiblChk: '',
      //step처리 -- 추후 containerDetail를 component로 빼면 바꾼다.
      step01: {}, // Empty Container Pick up
      step02: {}, // Port of Loading
      step03: {}, // On Board
      step04: {}, // Port of Discharging
      step05: {}, // Gate Out
      step05_1: {}, // ICD
      step06: {}, // Return
      step07: {}, // Gate Out(India)
      step08: {}, // Return(India)
      tsList: [], // TS 정보
      reTsList: [], // TS 정보
      tsInfo: {}, // TS 정보
      inland: [], // 내륙 운동
      inlandFlag: 'D', // 내륙 정보 플래그
      inlandEtaDay: '', // 내륙 정보
      inlandEtaTime: '', // 내륙 정보
      inlandEtdDay: '', // 내륙 정보
      inlandEtdTime: '', // 내륙 정보

      //검색한 containerList
      cntrList: [],
      selectCntrList: [],

      //초기에 보여줄 containerList
      container1: [],
      prdYn: process.env.VUE_APP_MODE !== 'PRD'
    }
  },
  computed: {
    ...rootComputed,
    cntrStsNm: function () {
      let nm = ''
      const cd = this.tsInfo.cntrStsCd

      if (this.$ekmtcCommon.isNotEmpty(cd)) {
        if (cd === 'DIS') {
          nm = 'Discharging'
        } else if (cd === 'LDG') {
          nm = 'Loading'
        } else if (cd === 'GTI') {
          nm = 'Gate In'
        } else if (cd === 'GTO') {
          nm = 'Gate Out'
        }
      }
      return nm
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const TH = this
      const detailList = TH.detailList
      const detailDateList = TH.detailDateList

      if (this.$ekmtcCommon.isNotEmpty(detailList) && this.$ekmtcCommon.isNotEmpty(detailDateList)) {
        this.getCargoTrackingDetail()
      }
    },
    async getCargoTrackingDetail () {
        const TH = this
        let trackingList = TH.detailList
        let trackingDateList = TH.detailDateList
        let inland = TH.detailInlandList
        let step01MvntDtTm = '00000000000000'
        let step01MvntDt = '00000000'
        let step01MvntTm = '000000'
        let mvntDtTm

        // 초기화
        TH.tsList = []
        TH.reTsList = []

        //메세지에 사용할 가장 최근 정보 가져오기
        TH.messageRk = TH.detailList[0].rk

        // 처음에 보여줄 내륙정보 셋팅 (마지막 LEG ETD/ETA 기준)
        if (this.$ekmtcCommon.isNotEmpty(TH.detailParams)) {
          if (this.$ekmtcCommon.isNotEmpty(TH.detailParams.inlandEtd)) {
            if (TH.detailParams.inlandEtd.indexOf('/') > 0) {
              TH.inlandEtdDay = TH.detailParams.inlandEtd.replace('.', '-').split('/')[0]
              TH.inlandEtdTime = TH.detailParams.inlandEtd.replace('.', '-').split('/')[1]
              TH.inlandEtaDay = TH.detailParams.inlandEta.replace('.', '-').split('/')[0]
              TH.inlandEtaTime = TH.detailParams.inlandEta.replace('.', '-').split('/')[1]
            }
          }
        }

        // 무브먼트 데이터 중 1.Empty Container Pick up 이전 데어티 제외(SR.1398294)
        for (let item of trackingList) {
          if (item.rk === 1) {
            step01MvntDt = item.mvntDt || '00000000'
            step01MvntTm = item.mvntTm || '000000'

            step01MvntDtTm = item.mvntDt + item.mvntTm || '00000000000000'
          }
        }

        // detail정보 셋팅
        if (trackingList.length > 0) {
          //ts_yn 여부
          if (TH.detailParams.tsYn !== 'Y') {
            for (let i = 0; i < trackingList.length; i++) {
              mvntDtTm = trackingList[i].mvntDt + trackingList[i].mvntTm

              if (step01MvntDtTm <= mvntDtTm) {
                if (trackingList[i].rk === 1) {
                  // Empty Container Pick up
                  TH.step01 = trackingList[i]
                } else if (trackingList[i].rk === 3) {
                  // Port of Loading
                  TH.step02 = trackingList[i]
                } else if (trackingList[i].rk === 5) {
                  // On Board
                  TH.step03 = trackingList[i]
                } else if (trackingList[i].rk === 8) {
                  // Port of Discharging
                  TH.step04 = trackingList[i]
                } else if (trackingList[i].rk === 10) {
                  // Gate Out
                  TH.step05 = trackingList[i]
                } else if (trackingList[i].rk === 9) {
                  // ICD
                  TH.step05_1 = trackingList[i]
                } else if ((trackingList[i].rk === 11 || (trackingList[i].rk === 3 && trackingList[i].rkNext === undefined)) && !this.$ekmtcCommon.isEmpty(TH.step06)) {
                  // Return
                  if (this.$ekmtcCommon.isEmpty(TH.step06.mvntDt) || TH.step06.mvntDt < trackingList[i].mvntDt) {
                  TH.step06 = trackingList[i]
                  }
                } else if (trackingList[i].rk === 12) {
                  // Gate Out(India)
                  TH.step07 = trackingList[i]
                } else if (trackingList[i].rk === 13) {
                  // Return(India)
                  TH.step08 = trackingList[i]
                }
              }
            }
          } else {
            for (let j = 0; j < trackingList.length; j++) {
              mvntDtTm = trackingList[j].mvntDt + trackingList[j].mvntTm

              if (step01MvntDtTm <= mvntDtTm) {
                if (trackingList[j].rk === 1 && trackingList[j].cntrMvntCd !== 'TS') {
                  // Empty Container Pick up
                  TH.step01 = trackingList[j]
                } else if (trackingList[j].rk === 3 && trackingList[j].cntrMvntCd !== 'TS') {
                  // Port of Loading
                  TH.step02 = trackingList[j]
                } else if (trackingList[j].rk === 5 && trackingList[j].cntrMvntCd !== 'TS') {
                  // On Board
                  TH.step03 = trackingList[j]
                } else if (trackingList[j].rk === 8 && trackingList[j].cntrMvntCd !== 'TS') {
                  // Port of Discharging
                  TH.step04 = trackingList[j]
                } else if (trackingList[j].rk === 10 && trackingList[j].cntrMvntCd !== 'TS') {
                  // Gate Out
                  TH.step05 = trackingList[j]
                } else if (trackingList[j].rk === 9 && trackingList[j].cntrMvntCd !== 'TS') {
                  // ICD
                  TH.step05_1 = trackingList[j]
                } else if ((trackingList[j].rk === 11 || (trackingList[j].rk === 3 && trackingList[j].rkNext === undefined && trackingList[j].cntrMvntCd !== 'TS')) && !this.$ekmtcCommon.isEmpty(TH.step06)) {
                  // Return
                  if (this.$ekmtcCommon.isEmpty(TH.step06.mvntDt) || TH.step06.mvntDt < trackingList[j].mvntDt) {
                  TH.step06 = trackingList[j]
                  }
                } else if (trackingList[j].rk === 12 && trackingList[j].cntrMvntCd !== 'TS') {
                  // Gate Out(India)
                  TH.step07 = trackingList[j]
                } else if (trackingList[j].rk === 13 && trackingList[j].cntrMvntCd !== 'TS') {
                  // Return(India)
                  TH.step08 = trackingList[j]
                } else if (trackingList[j].cntrMvntCd === 'TS') {
                  TH.tsList.push(trackingList[j])
                }
              }
            }

            // [TO-DO] trakingList에서 blNo가 없는 데이터를 가져오는 경우가 있으므로 해당 부분 제거
            //         Return단계도 없을 수 있으므로 TS부분에서만 제거
            if (TH.tsList.length > 0) {
              TH.tsList = TH.tsList.filter(item => {
                if (item.blNo !== undefined) {
                  return item
                }
              })

              TH.reTsList = TH.tsList.reverse()
              for (let k = 0; k < TH.reTsList.length; k++) {
                TH.reTsList[k].tsCnt = TH.reTsList.length
                TH.reTsList[k].tsIdx = k
              }
              // 처음에 TS화면에 보여줄 정보 설정
              //TH.reTsList = TH.tsList.reverse()
              //for (let k = 0; k < TH.reTsList.length; k++) {
              //  TH.reTsList[k].tsCnt = TH.reTsList.length
              //  TH.reTsList[k].tsIdx = k
              //}
              if (TH.rank > 6) {
                TH.tsInfo = TH.reTsList[TH.reTsList.length - 1]
              } else {
                TH.tsInfo = TH.reTsList[0]
              }
            }
          }
        }

        const cargoSteps = {
          step01: this.step01, // Empty Container Pick up
          step02: this.step02, // Port of Loading
          step03: this.step03, // On Board
          step04: this.step04, // Port of Discharging
          step05: this.step05, // Gate Out
          step06: this.step06, // Return
          step07: this.step07, // Gate Out(India)
          step08: this.step08 // Return(India)

        }

        this.$store.commit('trans/updateCargoSteps', cargoSteps)
        this.$store.commit('trans/updateTsList', this.tsList)
        this.$store.commit('trans/updateCargoDetailList', this.detailList)

        // if (this.rank >= 6 && this.reTsList.length > 2) {
        //   this.$store.commit('trans/updateTsInfo', TH.reTsList[TH.reTsList.length - 1])
        // } else {
          this.$store.commit('trans/updateTsInfo', this.tsInfo)
        // }

        TH.trackingList = trackingList
        TH.trackingDateList = trackingDateList
        TH.inland = inland
    },
    //ts정보 스크롤 처리
    preInfo (idx, flag) {
      if (flag === 'TS') {
        this.tsInfo = this.reTsList[idx - 1]
      } else if (flag === 'IN') {
        this.inInfo = this.reInList[idx + 1]
      }
    },
    nextInfo (idx, flag) {
      if (flag === 'TS') {
        this.tsInfo = this.reTsList[idx + 1]
      } else if (flag === 'IN') {
        this.inInfo = this.reInList[idx + 1]
      }
    },
    //inland 플래그 처리
    chgInlandFlag (flag) {
      if (this.$ekmtcCommon.isNotEmpty(flag)) {
        this.inlandFlag = flag
      }
    },
    //메시지 처리
    getMessage (svo) {
      const TH = this
      const flag = TH.dateFlag
      let msg = ''
      let dvo = this.detailDateList[0]
      let svoIn = ''
      // svo.rk = 12
      // svo.shprVanYn = 'N'

      if (svo.rk >= 12 && this.dlyCtrCd === 'IN') {
        svoIn = this.detailList[2]
      }

      for (let i = 0; i < this.detailList.length; i++) {
        if (this.detailList[i].rk === svo.rkNext) {
          prevVo = this.detailList[i]
        }
      }

      if (svo.rk === 1) {
        msg = this.$t('msg.CART010G010.001', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, prevctrCd: svo.plcNm, prevplcCd: svo.dlyCyNm, cgoClsDt: this.$ekmtcCommon.changeDatePattern(dvo.cgoClsDt), demDt: this.$ekmtcCommon.changeDatePattern(dvo.demDt), detDt: this.$ekmtcCommon.changeDatePattern(dvo.detDt), rkNext: svo.rkNext })
      } else if (svo.rk === 3 && svo.blNo !== undefined) {
        msg = this.$t('msg.CART010G010.002', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, etd: this.$ekmtcCommon.changeDatePattern(dvo.etd) })
      } else if (svo.rk === 5) {
        if (svo.rkNext === 6) {
          msg = this.$t('msg.CART010G010.004', { prevctrCd: svo.podPortNm, prevplcCd: svo.podTrmlNm })
          } else {
          msg = this.$t('msg.CART010G010.003', { eta: this.$ekmtcCommon.changeDatePattern(svo.eta), prevctrCd: svo.podPortNm, prevplcCd: svo.podTrmlNm })
        }
      } else if (svo.rk === 6) {
        msg = this.$t('msg.CART010G010.005', { plcNm: svo.plcNm, trmlNm: svo.trmlNm })
      } else if (svo.rk === 7) {
        msg = this.$t('msg.CART010G010.006', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, eta: this.$ekmtcCommon.changeDatePattern(dvo.eta), prevctrCd: svo.podPortNm, prevplcCd: svo.podTrmlNm })
      } else if (svo.rk === 8) {
        // 2022-04-08 화물추적에서 POD가 KR일시 OFC일자를 적용
        if (svo.shprVanYn === 'Y') {
          if (svo.ctrCd === 'KR') {
            msg = this.$t('msg.CART010G010.007_1', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.ofcDt, '-') })
          } else {
            // 2022-04-08 화물추적에서 DEM/ DET/ DET(COMBINED)/ OFC 여부에 따라 일자적용 (01 => DET(COMBINED), 02 => OFC, 03 => DEM/DET)
            if (flag === '01') {
              msg = this.$t('msg.CART010G010.007_1', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.detcbDt, '-') })
            } else if (flag === '02') {
              msg = this.$t('msg.CART010G010.007_1', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.ofcDt, '-') })
            } else {
              msg = this.$t('msg.CART010G010.007_1', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.demDt, '-') })
            }
          }
        } else {
          if (svo.ctrCd === 'KR') {
            msg = this.$t('msg.CART010G010.007', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.ofcDt, '-') })
          } else {
            // 2022-04-08 화물추적에서 DEM/ DET/ DET(COMBINED)/ OFC 여부에 따라 일자적용 (01 => DET(COMBINED), 02 => OFC, 03 => DEM/DET)
            if (flag === '01') {
              msg = this.$t('msg.CART010G010.007', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.detcbDt, '-') })
            } else if (flag === '02') {
              msg = this.$t('msg.CART010G010.007', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.ofcDt, '-') })
            } else {
              msg = this.$t('msg.CART010G010.007', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.demDt, '-') })
            }
          }
        }
      } else if (svo.rk === 9) {
        if (svo.shprVanYn === 'Y') {
          msg = this.$t('msg.CART010G010.007_1', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.demDt, '-') })
        } else {
          msg = this.$t('msg.CART010G010.007', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, demDt: this.$ekmtcCommon.changeDatePattern(dvo.demDt, '-') })
        }
      } else if (svo.rk === 10) {
        if (svo.ctrCd === 'KR') {
          msg = this.$t('msg.CART010G010.009', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, detDt: this.$ekmtcCommon.changeDatePattern(dvo.ofcDt, '-'), prevctrCd: svo.podPortNm, prevplcCd: svo.rtnCyNm })
        } else {
          msg = this.$t('msg.CART010G010.009', { plcNm: svo.plcNm, trmlNm: svo.trmlNm, detDt: this.$ekmtcCommon.changeDatePattern(dvo.detDt, '-'), prevctrCd: svo.podPortNm, prevplcCd: svo.rtnCyNm })
        }
      } else if (svo.rk === 11) {
        if (svo.plcCd === 'INC' || svo.plcCd === 'BGK') {
          const prevPlcCd = svo.plcNm.split(',')[0]
          msg = this.$t('msg.CART010G010.010', { prevplcCd: this.$ekmtcCommon.isNotEmpty(prevPlcCd) ? prevPlcCd : '', trmlNm: this.$ekmtcCommon.isNotEmpty(svo.rtnCyNm) ? svo.rtnCyNm : svo.trmlNm })
        } else {
          msg = this.$t('msg.CART010G010.010', { prevplcCd: this.$ekmtcCommon.isNotEmpty(svo.podPortNm) ? svo.podPortNm : '', trmlNm: this.$ekmtcCommon.isNotEmpty(svo.rtnCyNm) ? svo.rtnCyNm : svo.trmlNm })
        }
      } else if (svo.rk === 12) {
        msg = this.$t('msg.CART010G010.009', { plcNm: svoIn.plcNm, trmlNm: svo.trmlNm, detDt: this.$ekmtcCommon.changeDatePattern(dvo.detDt, '-'), prevctrCd: svoIn.podPortNm, prevplcCd: this.$ekmtcCommon.isNotEmpty(svoIn.rtnCyNm) ? svoIn.rtnCyNm : svo.trmlNm })
      } else if (svo.rk === 13) {
        msg = this.$t('msg.CART010G010.010_1', { prevplcCd: this.$ekmtcCommon.isNotEmpty(svoIn.podPortNm) ? svoIn.podPortNm : '', plcNm: svo.plcNm.split(',')[0], trmlNm: this.$ekmtcCommon.isNotEmpty(svoIn.rtnCyNm) ? svoIn.rtnCyNm : svo.trmlNm })
      }

      return msg
    }
  }
}
</script>

 <style scoped>
 </style>
